//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    country: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isOnlyApi: {
      type: Boolean,
      default: false
    },
    isDisabledBuy: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    countLabel: function () {
      const count = Number.parseFloat(this.country?.count || '0');
      if (count <= 0) {
        return this.$t('servicesList.quantityFew')
      }
      return this.$numberFormat(this.country?.count, 0, '')
    },
    priceLabel: function () {
      let price = this.$convertCurrency(this.country?.price);
      price = this.$numberFormat(Number.parseFloat(price), 2, '.');

      let currency = this.$store.getters["currency/currentCurrency"];
      currency = Boolean(currency === 'rub') ? '₽' : '$';

      return [price, currency].join(' ')
    }
  },

  methods: {
    buy: function () {
      if (this.isOnlyApi) {
        return
      }
      this.$emit('buy', this.country)
    }
  }
}
