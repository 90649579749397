//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CountryCard',

  props: {
    country: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isDisabledBuy: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    countLabel: function () {
      const count = Number.parseFloat(this.country?.count || '0');
      if (count <= 0) {
        return this.$t('servicesList.quantityFew')
      }

      return [
        this.$numberFormat(this.country?.count, 0, ''),
        this.$t('servicesList.quantity')
      ].join(' ');
    },
    priceLabel: function () {
      let price = this.$convertCurrency(this.country?.price);
      price = this.$numberFormat(Number.parseFloat(price), 2, '.');

      let currency = this.$store.getters["currency/currentCurrency"];
      currency = Boolean(currency === 'rub') ? '₽' : '$';

      return [price, currency].join(' ')
    }
  },

  methods: {
    buyNumber: function () {
      if (this.isDisabledBuy) {
        return null
      }
      this.$emit('buyNumber', this.country, 'country')
    }
  }
}
