//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ArticleCard',

  props: {
    article: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
