//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArticleCard from "./ArticleCard.vue";
export default {
  data: function () {
    return {
      isMore: false
    }
  },

  props: {
    items: {
      type: Array,
      default: () => {
        return []
      }
    },
    serviceName: {
      type: String,
      default: ""
    }
  },

  computed: {
    isShowMore: function () {
      return Boolean(this.items.length > 2 && !this.isMore);
    }
  },

  components: {
    ArticleCard
  },

  methods: {
    changeMore: function () {
      this.isMore = true;
    }
  }
}
